.socials {
  position: absolute;
  left: 80%;
  height: 15%;
  color: white;
  z-index: 2;
  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;

    .link {
      position: relative;
      top: 20px;
      opacity: 0;
    }

    .active {
      position: relative;
      top: 0;
      opacity: 1;
      transition: 0.5s;
    }

    li:hover {
      opacity: 0.6;
      cursor: pointer;
    }
    li > a {
      color: #fff;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact-layout {
    left: 78%;
    margin-bottom: 15px;
    bottom: 0;
  }
}

.contact-layout {
  bottom: 0;
  margin-bottom: 15px;
}

.home-layout {
  top: 50%;
}
