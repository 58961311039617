.home-left {
  position: relative;
  left: -0%;
  width: 55%;
  height: 100vh;
  background-color: #fff;
  animation: slide-perm 2s forwards;

  .left-container {
    position: absolute;
    z-index: 5;
    width: 100%;
    background-color: #fff;
    bottom: 0;
    min-height: 35%;
    padding-left: 18%;
    padding-top: 1rem;
    padding-bottom: 10px;

    .title {
      position: relative;
      left: 0;
      opacity: 0;
      top: 20px;
    }

    .about {
      position: relative;
      margin-top: 0.5rem;
      margin-right: 1rem;
      opacity: 0;
      top: 20px;

      .res {
        color: #dc4739;
      }

      .res:after {
        background-color: #dc4739;
      }
    }

    .active {
      position: relative;
      top: 0;
      opacity: 1;
      transition: 0.5s;
    }
  }
}

@media only screen and (max-width: 1224px) {
}

@media only screen and (max-width: 768px) {
  .home-left {
    height: 100%;
    position: fixed;
    width: 55%;
    z-index: 2;
    .left-container {
      background-color: transparent;
      color: black;
      margin-top: 70px;
      .title {
        font-size: 1.3rem;
      }

      .about {
        font-size: 0.9rem;
      }
    }
  }
}
