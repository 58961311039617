.header-container {
  position: relative;
  top: 20px;
  width: 100%;
  height: 6rem;
  opacity: 0;

  .logo {
    bottom: 0;
    position: absolute;
    left: 18%;
    background-color: white;
    z-index: 3;
    padding: 3px 6px;
    padding-left: 0;
    white-space: nowrap;
  }

  .nav-options {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    width: 50%;
    //justify-content: space-around;
    z-index: 3;
  }
}

.active {
  position: relative;
  top: 0;
  opacity: 1;
  transition: 0.5s;
}

@media only screen and (max-width: 1224px) {
  .header-container .nav-options {
    width: fit-content;
    right: unset;
    left: 18%;
    flex-direction: column;
    top: 120px;
    a {
      background-color: white;
      padding-right: 5px;
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }

  .header-container .logo {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .header-container .nav-options {
    top: 88px;
  }
}
