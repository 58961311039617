.form-container {
  max-height: 85vh;
  overflow-y: auto;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20%;
  position: absolute;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 25px;
  padding-top: 10px;
  animation: slide-perm-opacity 1s forwards;
  background-color: #fff;
  z-index: 2;

  .overlay {
    position: absolute;
    z-index: 4;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
  }

  .form-container {
    left: -100%;
    .contact-form {
      background-color: #fff;
    }
  }
}

.button-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: end;

  .submit-button {
    border: 1px solid #0e0e10;
    background-color: #fff;
    cursor: pointer;
    text-align: center;
    padding: 1rem;
    opacity: 1;
    transition: opacity 0.2s;
    color: black;
  }

  .submit-button:hover {
    opacity: 0.6;
  }

  .submit-button:disabled {
    opacity: 0.6;
    color: #dc4739;
  }
}

@media only screen and (max-width: 768px) {
  .form-container {
    width: 85%;
    top: 20%;
    height: 500px;
    max-height: none;
    overflow-y: scroll;
  }
}
