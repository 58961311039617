* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Mulish;
}

body,
html {
  background-color: #0e0e10;
  margin: 0;
  padding: 0;
  height: 100%;
}

h1 {
  font-size: 1.5rem;
}

a {
  color: black;
  text-decoration: none;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #000;
  width: fit-content;
}

.hover-underline-animation.inverted:after {
  background-color: #fff;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active {
  position: relative;
  top: 0;
  opacity: 1;
  transition: 0.5s;
}

@keyframes slide {
  0% {
    left: -100%;
  }
  25% {
    left: -100%;
  }
  75% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@keyframes slide-perm {
  0% {
    left: -100%;
  }
  25% {
    left: -100%;
  }
  75% {
    left: 0;
  }
  100% {
    left: 0;
  }
}

@keyframes slide-perm-opacity {
  0% {
    left: -150%;
    opacity: 0.5;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    top: -100%;
  }
  25% {
    top: -100%;
  }
  75% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

@keyframes slide-down-perm {
  0% {
    top: -100%;
  }
  25% {
    top: -100%;
  }
  75% {
    top: 0;
  }
  100% {
    top: 0;
  }
}

@media only screen and (min-width: 1224px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 320px) {
}
