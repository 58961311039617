.group {
  margin-top: 15px;
  position: relative;

  .form-input {
    background: none;
    background-color: rgba(20, 20, 20, 0.8);
    border: none;
    border-radius: 0;

    color: #fff;
    display: block;
    width: 100%;

    margin-top: 5px;
    padding: 20px;

    opacity: 1;
    transition: opacity 0.2s;
    width: 100%;
  }

  .form-input:focus {
    opacity: 0.9;
    outline: none;
  }

  .form-input-label {
    color: black;

    margin-bottom: 5px;
  }
}
