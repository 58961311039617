.image-container {
  position: absolute;
  left: 10%;
  top: 20%;
  width: 65%;
  height: 500px;
  overflow: hidden;

  .img {
    background-image: url("/assets/mtns-1080.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
  }

  .overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
  }
}

@media only screen and (max-width: 768px) {
  .image-container {
    width: 100%;
    z-index: 1;
  }
}
